import React from "react";
import "./HomeStyles.css";
import { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
const Policy = () => {
  const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

  return (
    <div className="flex flex-col min-h-screen bg-[#424530] font-suse">
      {/* Header */}
      <div>
        <div className="flex justify-between px-2 py-4 md:px-8 md:py-4">
          <div>
            <img
              alt="leadsify-logo"
              src="images/logo-white.png"
              className="w-16 h-auto sm:w-28 hover:cursor-pointer"
            />
          </div>

          {/* Hamburger menu button for small screens */}
          <div className="flex items-center md:hidden">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none"
            >
              {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
            </button>
          </div>

          {/* Links - visible on large screens */}
          <div className="flex-row items-center hidden space-x-4 md:flex sm:space-x-6">
            <p className="text-sm font-semibold text-white sm:text-lg md:text-2xl hover:cursor-pointer">
              <Link to="/" className="text-white">
                Home
              </Link>
            </p>
            {/* <p className="text-sm font-semibold text-white sm:text-lg md:text-2xl hover:cursor-pointer">
              <Link to="/policy" className="text-white">
                Policy
              </Link>
            </p> */}
            <p className="text-sm font-semibold text-white sm:text-lg md:text-2xl hover:cursor-pointer">
              <Link to="/privacy" className="text-white">
                Privacy
              </Link>
            </p>
            <p className="text-sm font-semibold text-white sm:text-lg md:text-2xl hover:cursor-pointer">
              <Link to="/contact" className="text-white">
                Contact Us
              </Link>
            </p>
            <p className="text-sm font-semibold text-white sm:text-lg md:text-2xl hover:cursor-pointer">
              <Link
                to="https://www.dev-portal.latsuccess.com/auth/login"
                className="text-white"
              >
                Log in
              </Link>
            </p>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`md:hidden flex flex-col items-center space-y-4 transition-all duration-500 ease-in-out overflow-hidden ${
            isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <p className="text-sm font-semibold text-white hover:cursor-pointer">
            <Link to="/" onClick={toggleMenu}>
              Home
            </Link>
          </p>
          <p className="text-sm font-semibold text-white hover:cursor-pointer">
            <Link to="/contact" onClick={toggleMenu}>
              Contact Us
            </Link>
          </p>
          <p className="text-sm font-semibold text-white hover:cursor-pointer">
            <Link
              to="https://www.dev-portal.latsuccess.com/auth/login"
              onClick={toggleMenu}
            >
              Log in
            </Link>
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-col px-6 py-8 space-y-8 md:px-16 lg:px-20 bg-[#fff8f2] text-[#424530]">
        <section className="space-y-4">
          <h2 className="text-2xl font-bold md:text-4xl">
            Welcome to LATsuccess Inc.
          </h2>
          <p className="text-lg md:text-xl">
            This Privacy Policy governs all Software, Applications, and Services
            provided by LATsuccess Inc. ("LAT", "we", "us", "our"). By using
            this Application/Software, you consent to the collection, use,
            storage, and disclosure of your personal information as described in
            this Privacy Policy. If you do not agree, please refrain from using
            our Application.
          </p>
          <p className="text-lg md:text-xl">
            This policy may be updated periodically, and continued use of the
            Services implies acceptance of the revised terms.
          </p>
        </section>

        <section className="space-y-4">
          <h3 className="text-xl font-bold md:text-3xl">
            1. What Personal Information Do We Collect?
          </h3>
          <p className="text-lg md:text-xl">
            You may access our Application without registering. If you choose to
            provide personal information, it may include but is not limited to:
          </p>
          <ul className="pl-4 space-y-2 list-disc list-inside">
            <li>Your name</li>
            <li>Residing address</li>
            <li>Postal address</li>
            <li>Email address</li>
            <li>Telephone number</li>
          </ul>
          <p className="text-lg md:text-xl">
            Information we collect automatically includes details sent by your
            device, such as interaction data, and additional data from third
            parties, including demographic and navigation data.
          </p>
        </section>

        <section className="space-y-4">
          <h3 className="text-xl font-bold md:text-3xl">
            2. How We Use Your Personal Information
          </h3>
          <p className="text-lg md:text-xl">
            Your personal information may be used for purposes including but not
            limited to:
          </p>
          <ul className="pl-4 space-y-2 list-disc list-inside">
            <li>Providing access to our Services and Customer Support</li>
            <li>Preventing illegal activities, fraud, and security breaches</li>
            <li>Personalizing and improving our Services</li>
            <li>Marketing communications via email, SMS, or phone</li>
          </ul>
          <p className="text-lg md:text-xl">
            We may also use social network integration services to access your
            information made available on those platforms.
          </p>
        </section>

        <section className="space-y-4">
          <h3 className="text-xl font-bold md:text-3xl">
            3. Transfer of Your Personal Information to Third Parties
          </h3>
          <p className="text-lg md:text-xl">
            We may disclose your personal information to third parties such as
            service providers, regulatory authorities, or companies we merge
            with. Information shared will remain under LAT's control unless
            required by law or for legal proceedings.
          </p>
          <ul className="pl-4 space-y-2 list-disc list-inside">
            <li>Stripe Inc. for payment processing</li>
            <li>Service providers for technical support and marketing</li>
            <li>Regulatory authorities as required by law</li>
          </ul>
        </section>

        <section className="space-y-4">
          <h3 className="text-xl font-bold md:text-3xl">
            4. Marketing Communication
          </h3>
          <p className="text-lg md:text-xl">
            We may use the information collected to send you offers, updates, or
            marketing messages. You can opt out of marketing communications
            through your account settings or the unsubscribe link.
          </p>
        </section>

        <section className="space-y-4">
          <h3 className="text-xl font-bold md:text-3xl">
            5. Protection of Your Personal Information
          </h3>
          <p className="text-lg md:text-xl">
            We protect your personal information using technical measures like
            firewalls and encryption, but cannot guarantee complete security.
          </p>
        </section>

        <section className="space-y-4">
          <h3 className="text-xl font-bold md:text-3xl">
            6. Information from Users Under 18
          </h3>
          <p className="text-lg md:text-xl">
            We do not knowingly collect information from users under 18. If you
            are a parent and become aware that your child has provided
            information, please contact us to remove it.
          </p>
        </section>

        <section className="space-y-4">
          <h3 className="text-xl font-bold md:text-3xl">
            7. Accessing, Reviewing, and Changing Your Personal Information
          </h3>
          <p className="text-lg md:text-xl">
            You can adjust your information through your LAT account. To close
            your account, contact us, and we will process your request as
            required by law.
          </p>
        </section>

        <section className="space-y-4">
          <h3 className="text-xl font-bold md:text-3xl">8. Definitions</h3>
          <p className="text-lg md:text-xl">
            Terms such as "LAT," "Services," and "Third Party" are defined for
            clarity within this Privacy Policy.
          </p>
        </section>

        <section className="space-y-4">
          <h3 className="text-xl font-bold md:text-3xl">9. Contact</h3>
          <p className="text-lg md:text-xl">
            For any questions about LAT or our Services, please contact us at:
            <strong> LATsuccess Inc.</strong>
          </p>
        </section>
      </div>

      {/* Footer */}
      <Footer/>
    </div>
  );
};

export default Policy;
