import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Policy from "./pages/Policy";
import Contact from "./pages/Contact";
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />{" "}
          <Route path="/policy" element={<Policy />} />{" "}
          <Route path="/privacy" element={<Policy />} />{" "}
          <Route path="/contact" element={<Contact />} />{" "}
        </Routes>
      </Router>
    </div>
  );
}

// hmm ok

export default App;
