const Footer=()=>{
    return(
        <div className="flex justify-center py-6 bg-[#424530] text-white">
            <p className="text-lg text-center">
            {new Date().getFullYear()} , All Copy Rights Reserved by <b> LATsuccess Inc.</b> 

            {/* <span className="mx-2 hover:cursor-pointer">Facebook</span>|
            <span className="mx-2 hover:cursor-pointer">Twitter</span>|
            <span className="mx-2 hover:cursor-pointer">LinkedIn</span>|
            <span className="mx-2 hover:cursor-pointer">Instagram</span> */}
            </p>
        </div>
    )
}

export default Footer